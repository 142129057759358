import { useMemo } from 'react'
import { MENU_ITEMS } from '../constants/menu'

export const indexCrumb = { name: 'Главная', link: '/', key: 'index' }

export const useBreadCrumbs = (current, parent, currentTitle) => {
  const p = MENU_ITEMS.find(i => i.key === parent)

  const parentCrumbs = useMemo(() => {
    if (!p) {
      return [indexCrumb]
    } else {
      return [indexCrumb, p]
    }
  }, [p])

  return useMemo(() => {
    const curr = MENU_ITEMS.find(i => i.key === current) || {
      link: [p.link, '/', current].join(''),
      key: [p.key, '--', current].join(''),
      name: currentTitle,
    }

    return [...parentCrumbs, curr]
  }, [parentCrumbs, current, currentTitle, p])
}
