import React from 'react'
import { Link } from 'gatsby'
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from '@material-ui/core'
import './products.scss'

const Products = ({ products }) => {
  return (
    <Grid container>
      {products.map(e => {
        const product = e.node
        return (
          <Grid
            item
            md={6}
            xs={12}
            lg={4}
            key={product.slug}
            className="products__item"
          >
            <Box mr={3} mb={3} className="products__item-wrap">
              <Link
                to={`/products/${product.slug}`}
                className="products__item-link"
              >
                <Card>
                  <CardContent>
                    <Box mb={2}>
                      <Typography
                        variant="h5"
                        component="h3"
                        className="products__item-title"
                      >
                        {product.title}
                      </Typography>
                    </Box>
                    <CardMedia
                      style={{ width: '200px', height: '150px' }}
                      image={product.image.file.url}
                      title={product.image.title}
                      alt={product.image.title}
                    />
                    <Box height={150}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {product.caption}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default Products
