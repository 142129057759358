import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Products from '../components/products/products'
import { graphql } from 'gatsby'
import { useBreadCrumbs } from '../hooks/useBreadcrumbs'
import { Grid } from '@material-ui/core'

const ProductsPage = props => {
  const products = props.data.allContentfulProducts.edges
  const breadCrumbs = useBreadCrumbs('products')

  return (
    <Layout breadCrumbs={breadCrumbs} title="Продукция">
      <SEO title="Продукция пенетрон" />
      <Grid container>
        <Products products={products} />
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulProducts(sort: { fields: hotProduct }) {
      edges {
        node {
          title
          slug
          caption
          image {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default ProductsPage
